<template>
	<div class="about">
		<div class="navigate-body">
			<div class="logo-wraper">
				<el-image @click="openHome" class="logo" :src="require('../assets/images/logo1.png')" />
			</div>
			<div class="navigate-btns">
				<div class="nav-btn" @click="openHomePage">首页</div>
        <div class="nav-btn" @click="openPcPage">电脑端学习</div>
				<div class="nav-btn" @click="openDownload">应用下载</div>
				<div class="nav-btn active-nav-btn">关于我们</div>
			</div>
		</div>

		<div class="about-banner">
			<el-image fit="cover" class="about-banner-cover" :src="require('../assets/images/aboutBanner.png')" />
		</div>

		<!-- 关于淘知学堂 -->
		<div class="about-taozhi">
			<div :class="{'about-title': true, 'animate__animated': true, 'animate__fadeInUp': animate_a }">
				<img src="../assets/images/titleLeft.png" alt="">
				<span>
					关于淘知学堂
				</span>
				<img src="../assets/images/titleRight.png" alt="">
			</div>
			<div :class="{'about-msg': true, 'animate__animated': true, 'animate__fadeInUp': animate_b }">
				<p>
					北京淘知在线教育科技有限公司成立于2020年。原淘知学堂在线教育品牌因业务模型的增长、业务品类的增多以及影响力的扩大，现注册淘知在线教育。
				</p>
				<p>
					经多年打造与运营，公司的主平台——淘知学堂®日益受到广大师生家长的喜爱。平台现有在线课程2万余节，涵盖小初高全学科，10大分类，51类专题，仅视频总时长已达数十万分钟。
				</p>
			</div>
		</div>

		<!-- 产品介绍 -->
		<div class="product">
			<div :class="{'about-title': true, 'animate__animated': true, 'animate__fadeInUp': animate_c }">
				<img src="../assets/images/titleLeftA.png" alt="">
				<span>
					产品介绍
				</span>
				<img src="../assets/images/titleRightA.png" alt="">
			</div>
			<div :class="{'about-msg': true,'animate__animated': true, 'animate__fadeInUp': animate_d }">
				淘知学堂，致力于以纸质产品增值服务为宗旨，打造K12阶段在线教育品牌，在遵循传统教育教学理念的基础上，力求为学生解决课前预习难，课中听不懂，课后想复习等一系列学习难题。目前，平台最高日活突破300万，日浏览量2000万，公众号粉丝超过350万人，淘知学堂已成为广大中小学生在线学习的智慧选择。
			</div>

			<div :class="{'product-list': true,'animate__animated': true, 'animate__fadeInUp': animate_e }">
				<div class="product-item">
					<div class="title">微信公众号关注</div>
					<div class="number">
						<countTo ref="countToA" :autoplay="false" :startVal="0" :endVal="350" :duration="3000">
						</countTo>
						万+
					</div>
				</div>
				<div class="product-item">
					<div class="title">在线课程数</div>
					<div class="number">
						<countTo ref="countToB" :autoplay="false" :startVal="0" :endVal="20000" :duration="3000">
						</countTo>
						+
					</div>
				</div>
				<div class="product-item">
					<div class="title">服务的教辅图书数</div>
					<div class="number">
						<countTo ref="countToC" :autoplay="false" :startVal="0" :endVal="3000" :duration="3000">
						</countTo>
						+
					</div>
				</div>
				<div class="product-item">
					<div class="title">平台注册用户</div>
					<div class="number">
						<countTo ref="countToD" :autoplay="false" :startVal="0" :endVal="900" :duration="3000">
						</countTo>
						万+
					</div>
				</div>
			</div>
		</div>

		<!-- 原创课程 -->
		<div class="course">
			<div :class="{'about-title': true,'animate__animated': true, 'animate__fadeInUp': animate_f }">
				<img src="../assets/images/titleLeft.png" alt="">
				<span>
					原创课程
				</span>
				<img src="../assets/images/titleRight.png" alt="">
			</div>
			<div :class="{'course-list': true, 'animate__animated': true, 'animate__fadeInUp': animate_g }">
				<div class="course-item">
					<img src="../assets/images/course-item-a.png" alt="">
					<p class="title">趣味动漫</p>
					<p class="msg">古诗朗读、古诗解读、课文朗读、同步作文、脑洞大开的数学、识字动漫</p>
				</div>
				<div class="course-item">
					<img src="../assets/images/course-item-b.png" alt="">
					<p class="title">专题课程</p>
					<p class="msg">拼音、句子、句型、硬笔书法、语法、数学题型、物化生实验课程</p>
				</div>
				<div class="course-item">
					<img src="../assets/images/course-item-c.png" alt="">
					<p class="title">同步课程</p>
					<p class="msg">方法、写法、知识点、能力点、教材习题、思维训练营、应用题、科学、复习微课</p>
				</div>
				<div class="course-item">
					<img src="../assets/images/course-item-d.png" alt="">
					<p class="title">智能点学书</p>
					<p class="msg">图书采用动漫、音频与文字多轨并行的编排形式，一套图书多种用法，可以看，可以听。</p>
				</div>
			</div>

			<div class="foot-bac-icon">
				<img src="../assets/images/foot-bac-icon.gif" alt="">
			</div>

			<div :class="{'foot-bottom-list': true,'animate__animated': true, 'animate__fadeInUp': animate_h }">
				<div class="foot-list">
					<div class="foot-item">
						<img src="../assets/images/foot-item-a.png" alt="">
						<p class="foot-item-title">内容合作</p>
						<p class="foot-item-text">精品内容资源对接，扩大曝光</p>
					</div>
					<div class="foot-item">
						<img src="../assets/images/foot-item-b.png" alt="">
						<p class="foot-item-title">广告合作</p>
						<p class="foot-item-text">精准品牌营销方案，广而告之</p>
					</div>
					<div class="foot-item">
						<img src="../assets/images/foot-item-c.png" alt="">
						<p class="foot-item-title">代理合作</p>
						<p class="foot-item-text">打通各路推广渠道，互利互惠</p>
					</div>
					<div class="foot-item">
						<img src="../assets/images/foot-item-d.png" alt="">
						<p class="foot-item-title">平台合作</p>
						<p class="foot-item-text">跨平台流量、服务协作，优势互补</p>
					</div>
				</div>
				<div class="foot-list-btn">
          联系我们：4000659885
				</div>
			</div>
		</div>

    <copyright-notice/>

	</div>
</template>

<script>
	import countTo from 'vue-count-to';
  import CopyrightNotice from '../components/Layout/CopyrightNotice.vue'

	export default {
		name: "AboutUs",
		components: { countTo, CopyrightNotice },
		data() {
			return {
				isStart: true,
				animate_a: false,
				animate_b: false,
				animate_c: false,
				animate_d: false,
				animate_e: false,
				animate_f: false,
				animate_g: false,
				animate_h: false,
			}
		},
		mounted() {
			window.document.title = '淘知学堂 - 关于我们'
			window.scrollTo(0, 0);
			window.onscroll = () => {
				// 变量t是滚动条滚动时，距离顶部的距离
				let t = document.documentElement.scrollTop || document.body.scrollTop;
				if (t > 900 && this.isStart) {
					this.$refs.countToA.start();
					this.$refs.countToB.start();
					this.$refs.countToC.start();
					this.$refs.countToD.start();
					this.isStart = false;
				}

				if (t > 50) {
					this.animate_a = true;
				}
				if (t > 200) {
					this.animate_b = true;
				}
				if (t > 700) {
					this.animate_c = true;
				}
				if (t > 800) {
					this.animate_d = true;
				}
				if (t > 1100) {
					this.animate_e = true;
				}
				if (t > 1600) {
					this.animate_f = true;
				}
				if (t > 1800) {
					this.animate_g = true;
				}
				if (t > 2400) {
					this.animate_h = true;
				}


			}
		},
		methods: {
			/**
			 * 跳转到首页
			 */
			openHomePage() {
				this.$router.push({
					path: '/'
				});
			},
      openPcPage() {
        window.open('https://www.taozhi.cn/pcweb/')
      },
			/**
			 * 跳转到应用页面
			 */
			openDownload() {
				this.$router.push({
					path: '/download'
				});
			},
			openHome() {
				this.$router.push({
					path: '/'
				});
			},
			/**
			 * 联系我们
			 */
			contactUs() {
				window.open('https://www.taozhi.cn/mq_chat.htm')
			}
		}
	}
</script>
<style scoped lang="scss">
	.navigate-body {
		width: 100%;
		min-width: 1200px;
		height: 80px;
		background-color: #fff;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 9;
		padding: 0 120px;
		box-sizing: border-box;
		-webkit-box-shadow: 0px 8px 6px 5px #cbecff;
		-moz-box-shadow: 0px 8px 6px 5px #cbecff;
		box-shadow: 0px 8px 6px 0px rgba(203, 236, 255, 0.2);
		display: flex;
		justify-content: space-between;

		.logo-wraper {
			width: 176px;
			height: 113px;
			overflow: hidden;
		}

		.logo {
			width: 185px;
			cursor: pointer;
			fliter: drop-shadow(0px 1px 1px #edf8ff);
			-webkit-filter: drop-shadow(0px 1px 1px #edf8ff);
		}

		.navigate-btns {
			display: flex;
			height: 80px;
			padding: 15px 0;

			.nav-btn {
				height: 50px;
				line-height: 50px;
				margin: 0 20px;
				font-size: 18px;
				color: #2A536B;
				cursor: pointer;
				background-image: url("../assets/images/nav-icon.png");
				background-repeat: no-repeat;
				background-size: 24px 4px;
				background-position: center 60px;
				transition: all .3s;
			}

			.nav-btn:hover {
				color: #00A2FF;
				background-position: center 46px;
			}

			.active-nav-btn {
				color: #00A2FF;
				background-image: url("../assets/images/nav-icon.png");
				background-repeat: no-repeat;
				background-size: 24px 4px;
				background-position: center 46px;
			}
		}
	}

	.about-banner {
		width: 100%;
		min-width: 1200px;

		.about-banner-cover {
			height: 682px;
		}
	}

	.about-taozhi {
		width: 100%;
		min-width: 1200px;
		height: 660px;
		background-image: url("../assets/images/aboutBac.png");
		background-position: left center;
		background-repeat: no-repeat;
		-webkit-background-size: 1920px 660px;
		background-size: 1920px 660px;

		.about-title {
			color: #2A536B;
			opacity: 0;
		}

		.about-msg {
			width: 1200px;
			font-size: 18px;
			color: #2A536B;
			margin: 0 auto;
			padding-top: 60px;
			line-height: 32px;
			opacity: 0;
		}
	}

	.about-title {
		width: 100%;
		height: 270px;
		line-height: 50px;
		display: flex;
		align-items: center;
		justify-content: center;
		padding-top: 220px;

		span {
			font-size: 48px;
			padding: 0 30px;
		}

		img {
			width: 221px;
			height: 4px;
		}
	}

	.product {
		width: 100%;
		height: 851px;
		background-image: url("../assets/images/product.png");
		background-position: left center;
		background-repeat: no-repeat;
		-webkit-background-size: 1920px 851px;
		background-size: 1920px 851px;

		.about-title {
			color: #ffffff;
			opacity: 0;
		}

		.about-msg {
			width: 1200px;
			font-size: 18px;
			color: #ffffff;
			margin: 0 auto;
			padding-top: 60px;
			line-height: 32px;
			opacity: 0;
		}

		.product-list {
			width: 1200px;
			display: flex;
			justify-content: space-between;
			margin: 118px auto 0;
			opacity: 0;

			.product-item {
				width: 276px;
				height: 156px;
				background-color: #fff;
				-webkit-border-radius: 10px;
				-moz-border-radius: 10px;
				border-radius: 10px;

				.title {
					color: #2A536B;
					font-size: 24px;
					text-align: center;
					line-height: 1;
					padding: 39px 0 24px 0;
				}

				.number {
					font-size: 36px;
					color: #00A2FF;
					text-align: center;
				}
			}
		}
	}

	.course {
		width: 100%;
		height: 1430px;
		background-image: url(../assets/images/foot-bac.png);
		background-repeat: no-repeat;
		-webkit-background-size: 1920px;
		background-size: 1920px;
		background-position: left bottom;
		position: relative;
		top: 1px;

		.about-title {
			opacity: 0;
		}

		.foot-bac-icon {
			width: 125px;
			height: 171px;
			position: absolute;
			top: 735px;
			left: 154px;

			img {
				width: 100%;
				height: 100%;
			}
		}

		.course-list {
			width: 1200px;
			margin: 0 auto;
			display: flex;
			justify-content: space-between;
			padding-top: 109px;
			opacity: 0;

			.course-item {
				width: 276px;

				img {
					width: 276px;
					height: 156px;
					-webkit-border-radius: 10px;
					-moz-border-radius: 10px;
					border-radius: 10px;
				}

				.title {
					width: 100%;
					text-align: center;
					font-size: 24px;
					color: #2A536B;
					font-weight: bold;
					padding: 30px 0 20px 0;
				}

				.msg {
					font-size: 18px;
					color: #668190;
					line-height: 32px;
				}
			}
		}

		.foot-bottom-list {
			width: 1200px;
			margin: 400px auto 0;
			opacity: 0;

			.foot-list {
				width: 680px;
				display: flex;
				justify-content: space-between;

				.foot-item {
					width: 116px;
					display: flex;
					flex-wrap: wrap;
					justify-content: center;

					p {
						width: 100%;
						text-align: center;
					}

					.foot-item-title {
						font-size: 16px;
						color: #FFFFFF;
						line-height: 32px;
					}

					.foot-item-text {
						font-size: 14px;
						color: #ffffff;
						line-height: 24px;
					}

					img {
						width: 36px;
						height: 36px;
					}
				}
			}

			.foot-list-btn {
				width: 400px;
				height: 60px;
				background-color: #fff;
				border-radius: 30px;
				text-align: center;
				line-height: 60px;
				font-size: 28px;
				color: #00A2FF;
				cursor: pointer;
				margin: 60px 0 0 245px;
			}
		}
	}

	.foot-bottom {
		height: 71px;
		width: 100%;
		background-color: #0C9CEF;
		padding-top: 15.5px;
		box-sizing: border-box;

		p {
			width: 100%;
			text-align: center;
			font-size: 12px;
			color: #ffffff;
			line-height: 20px;
			span {
				padding: 0 20px;
			}
		}
	}

	@media screen and (max-width: 1440px) {
		.course .foot-bottom-list .foot-list-btn {
			width: 560px;
			height: 45px;
			line-height: 45px;
			font-size: 21px;
		}

		.course {
			-webkit-background-size: 1440px;
			background-size: 1440px;
		}

		.course .foot-bottom-list {
			margin: 450px auto 0;
		}

		.course .foot-bac-icon {
			top: 860px;
			left: 116px;
		}
	}

	@media screen and (min-width: 1920px) {

		.about-taozhi,
		.product,
		.course {
			background-size: 100%;
		}
		.about-banner .about-banner-cover {
			height: auto;
			width: 100%;
		}
	}
</style>
